import { useEffect, useState } from 'react';
import { IQuestion } from 'types/questionnaire';
import DropDownProps from '../OptionalPropsComponents/DropDownProps';
import CheckboxProps from '../OptionalPropsComponents/CheckboxProps';
import NumberProps from '../OptionalPropsComponents/NumberProps';
import DateTimeProps from '../OptionalPropsComponents/DateTimeProps';
import RadioButtonsProps from '../OptionalPropsComponents/RadioButtonsProps';
import RatingProps from '../OptionalPropsComponents/RatingProps';
import ScaleProps from '../OptionalPropsComponents/ScaleProps';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import clsx from 'classnames';
import TextInputProps from '../OptionalPropsComponents/TextInputProps';

const RATING_TYPES: { [key: string]: any } = {
  THREE: '3 stars',
  FIVE: '5 stars',
  TEN: '10 stars',
};

interface IProps {
  question?: IQuestion;
  togglePopover?: any;
  isPopoverOpen?: boolean;
  title: string;
  optionalPropsPopover: any;
  setOptionalPropsPopover: any;
  onSaveQuestion: any;
  icon: any;
  closePopups?: any;
  isStandardForm?: boolean;
}
const QuestionOptionalProps: React.FC<IProps> = ({
  question,
  title = '',
  icon,
  optionalPropsPopover,
  setOptionalPropsPopover,
  onSaveQuestion,
  closePopups,
  isStandardForm,
}: IProps) => {
  const { setAddQuestionError, addQuestionError, selectedQuestionnairy } = useQuestionnaireStore();
  const [isHover, setIsHover] = useState(false);

  const showScore = selectedQuestionnairy?.questionnaire?.type === 'E_PRO';
  const getContent = () => {
    switch (question?.questionType) {
      case 'TEXT_INPUT':
        return (
          <TextInputProps
            onSaveQuestion={onSaveQuestion}
            question={question}
            setOptionalPropsPopover={setOptionalPropsPopover}
          />
        );
      case 'CHECKBOX':
        return (
          <CheckboxProps
            onSaveQuestion={onSaveQuestion}
            setOptionalPropsPopover={setOptionalPropsPopover}
            question={question}
            optionalPropsPopover={optionalPropsPopover}
            showScore={showScore}
            isStandardForm={isStandardForm}
          />
        );
      case 'RADIO_BUTTONS':
        return (
          <RadioButtonsProps
            setOptionalPropsPopover={setOptionalPropsPopover}
            onSaveQuestion={onSaveQuestion}
            question={question}
            optionalPropsPopover={optionalPropsPopover}
            showScore={showScore}
            isStandardForm={isStandardForm}
          />
        );
      case 'DROPDOWN':
        return (
          <DropDownProps
            setOptionalPropsPopover={setOptionalPropsPopover}
            onSaveQuestion={onSaveQuestion}
            question={question}
            optionalPropsPopover={optionalPropsPopover}
            showScore={showScore}
            closePopups={closePopups}
            isStandardForm={isStandardForm}
          />
        );
      case 'RATING':
        return (
          <RatingProps
            setOptionalPropsPopover={setOptionalPropsPopover}
            onSaveQuestion={onSaveQuestion}
            question={question}
            showScore={showScore}
          />
        );
      case 'NUMBER_VALUE':
        return (
          <NumberProps
            setOptionalPropsPopover={setOptionalPropsPopover}
            onSaveQuestion={onSaveQuestion}
            question={question}
            questionnairy={selectedQuestionnairy}
          />
        );
      case 'SCALE':
        return (
          <ScaleProps
            setOptionalPropsPopover={setOptionalPropsPopover}
            onSaveQuestion={onSaveQuestion}
            question={question}
            showScore={showScore}
          />
        );
      case 'DATE_TIME':
        return (
          <DateTimeProps
            setOptionalPropsPopover={setOptionalPropsPopover}
            onSaveQuestion={onSaveQuestion}
            question={question}
          />
        );

      default:
        return null;
    }
  };
  useEffect(() => {
    if (addQuestionError == '' && question?.questionType === 'RATING') {
      setOptionalPropsPopover(false);
    }
  }, [addQuestionError]);

  return (
    <div className=' hover:[&>div]:!text-blue-normal flex flex-1 justify-end  items-center relative border-none '>
      <div
        className={clsx(
          'text-gray-medium text-sm gap-x-3 ml-3 flex justify-start items-center [&>svg]:z-1 ',
          isHover && '[&>svg>path]:!stroke-blue-normal [&>svg]:!stroke-blue-normal ',
        )}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          setAddQuestionError(null);
          setOptionalPropsPopover(!optionalPropsPopover);
        }}
      >
        {icon}
        {question?.questionType === 'RATING' && question?.stars ? (
          <span className=' pt-[2px]'>{RATING_TYPES[question?.stars as string]}</span>
        ) : (
          title
        )}
      </div>

      {getContent() && optionalPropsPopover && <>{getContent()}</>}
    </div>
  );
};

export default QuestionOptionalProps;
