import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'classnames';
import { useOutsideClick } from 'hooks/useOutsideClick';
import EditOption from '../EditOption';
import ScoreComponent from '../ScoreComponent';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { generateLinearNumbers } from 'utils/number';
import { areArraysEqual } from 'utils/array';
import { useLocation } from 'react-router-dom';

import '../textAreaStyle.css';

interface IProps {
  showScore?: boolean;
  onSaveQuestion: any;
  question: IQuestion;
  setOptionalPropsPopover: any;
  optionalPropsPopover?: boolean;
  closePopups?: any;
  isStandardForm?: boolean;
}
let tmpScores: any = null;
let tmpQuestion: any = null;
let tmpOptions: any = null;
let localOptionChanges: any[] = [];
let localValue: string = '';
let localNewOptionScoreValue: any = null;
const DropDownProps: React.FC<IProps> = ({
  showScore,
  onSaveQuestion,
  question,
  optionalPropsPopover,
  isStandardForm,
}: IProps) => {
  const location = useLocation();
  
  const scrollRef = useRef<HTMLInputElement>(null);
  const { addQuestionError, setAddQuestionError, selectedQuestionnairy } = useQuestionnaireStore();
  const [options, setOptions] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isEditingOption, setIsEditingOption] = useState<boolean>(false);
  const newOptionScoreInput = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<any>('');
  const [scores, setScores] = useState<any[]>([]);
  const [addNewOptionScore, setAddNewOptionScore] = useState<boolean>(false);
  const [addNewOptionScoreValue, setAddNewOptionScoreValue] = useState<any>(null);
  const [optionChanges, setOptionChanges] = useState<any[]>([]);

  localOptionChanges = optionChanges;
  useEffect(() => {
    if (question?.questionOptions?.length) {
      const tmp: any[] = [];
      question.questionOptions.map((opt: any, idx: number) => {
        if (opt.score || isEqualZero(opt.score)) tmp[idx] = opt.score;
        else tmp[idx] = scores?.[idx] ?? null;
      });

      setOptions(question?.questionOptions?.map((it: any) => it?.value));
      setScores(tmp);
    }
  }, [question?.questionOptions]);
  const handleUserKeyPress = useCallback((event: any) => {
    if (event.key === 'Enter') {
      if (optionalPropsPopover) handleClick();
    }
  }, []);
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  tmpScores = scores;
  tmpQuestion = question;
  tmpOptions = options;
  localValue = value;
  localNewOptionScoreValue = addNewOptionScoreValue;
  const isEqualZero = (val?: any) => +val === 0 && (val === '0' || val === 0);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      
      if (value) handleAddToOptions(value, true);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target?.value);
    setAddQuestionError(null);
  };
  const handleBlur = () => {
    setAddQuestionError(null);
    if (value) handleAddToOptions(value, true);
  };
  const handleAddToOptions = (val: string, isNew?: boolean, newOptions?: any) => {
    const firstOptions = newOptions ? [...newOptions] : [...options];

    const cleanedOptions = firstOptions?.map((opt: any) => opt?.toLowerCase());
    if (
      cleanedOptions?.indexOf(val?.toLowerCase()?.trim()) > -1 ||
      (val?.toLocaleLowerCase() === 'other' && cleanedOptions?.indexOf('other') > -1)
    )
      return;
    setAddQuestionError(null);
    const tmpOptions = [...firstOptions];
    const fndIdx: number = tmpOptions.indexOf(val);

    if (
      isEditing &&
      !addNewOptionScoreValue &&
      scores.reduce((acc, cur) => !!acc || !!cur || isEqualZero(cur), false) &&
      selectedQuestionnairy?.questionnaire?.type !== 'E_CRF'
    ) {
      setAddNewOptionScore(true);
      setTimeout(() => {
        if (newOptionScoreInput.current && !addNewOptionScoreValue)
          newOptionScoreInput.current.focus();
      }, 10);
    } else {
      if (fndIdx > -1) {
        tmpOptions[fndIdx] = val;
        setOptions([...tmpOptions]);
        scrollToBottom();
      } else if (val && tmpOptions.length < 20) {
        const newOptions = [...tmpOptions, val];

        if (
          selectedQuestionnairy?.questionnaire?.type === 'E_PRO' &&
          tmpScores?.length <= question?.questionOptions?.length &&
          (question?.questionOptions?.some((opt: any) => opt.score != null) ||
            tmpScores?.some((score: any) => score != null))
        ) {
          return;
        } else {
          onSaveQuestion({
            optionalProps: {
              isNew: isNew,
              questionOptions: newOptions.map((opt: string, idx: number) => ({
                label: opt,
                value: opt,
                score:
                  idx === newOptions?.length - 1 &&
                  (localNewOptionScoreValue || isEqualZero(localNewOptionScoreValue))
                    ? +localNewOptionScoreValue
                    : !(isEqualZero(tmpScores[idx]) || tmpScores[idx] === '')
                      ? tmpScores[idx] ||
                        question?.questionOptions?.[idx]?.score ||
                        isEqualZero(question?.questionOptions?.[idx]?.score)
                        ? question?.questionOptions?.[idx]?.score
                        : null
                      : +tmpScores[idx] || isEqualZero(tmpScores[idx])
                        ? +tmpScores[idx]
                        : null,
              })),
              isScored:
                tmpScores.length == 0 &&
                (addNewOptionScoreValue || isEqualZero(addNewOptionScoreValue))
                  ? true
                  : !tmpScores.reduce(
                        (acc: any, cur: any, id: number) =>
                          !!acc ||
                          isEqualZero(acc) ||
                          ((addNewOptionScoreValue || isEqualZero(addNewOptionScoreValue)) &&
                          id == tmpScores.length - 1
                            ? !!addNewOptionScoreValue
                            : !!cur),
                        false,
                      )
                    ? false
                    : true,
            },
          });
          const tmScores: any = newOptions.map((opt: string, idx: number) => ({
            label: opt,
            value: opt,
            score:
              idx === newOptions.length - 1 &&
              (addNewOptionScoreValue || isEqualZero(addNewOptionScoreValue))
                ? +addNewOptionScoreValue
                : !(isEqualZero(tmpScores[idx]) || tmpScores[idx] === '')
                  ? question?.questionOptions?.[idx]?.score ||
                    isEqualZero(question?.questionOptions?.[idx]?.score)
                    ? question?.questionOptions?.[idx]?.score
                    : null
                  : +tmpScores[idx] || isEqualZero(tmpScores[idx])
                    ? +tmpScores[idx]
                    : null,
          }));
          tmpScores = tmScores?.map((sc: any) =>
            isNew && selectedQuestionnairy?.questionnaire?.type === 'E_CRF' ? null : sc?.score,
          );
          setScores([...tmpScores]);
          setOptions(newOptions);
          scrollToBottom();
        }
      }
      setAddNewOptionScore(false);
      setAddNewOptionScoreValue(null);
      setIsEditing(false);
      setValue('');
    }
  };

  const handleClick = () => {
    setIsEditing(true);
    setAddQuestionError(null);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
  };
  const handleOutsideClick = useCallback(
    (e: any, clickedOut: boolean) => {
      if (clickedOut) {
        handleAddToOptions(value);
      }
    },
    [setIsEditing, value, addQuestionError, options, isEditing, addNewOptionScoreValue],
  );
  const handleDeleteOption = (e: any, opt: string) => {
    e.stopPropagation();
    const newOptns = [...options].filter((option: string) => option !== opt);
    const filteredOpts = question?.questionOptions?.filter((op: any) => op.value !== opt);

    onSaveQuestion({
      optionalProps: {
        questionOptions: newOptns.map((opt1: string) => ({
          label: opt1,
          value: opt1,
          score: filteredOpts.find((op: any) => op.value === opt1)?.score,
        })),
        isScored: !filteredOpts.reduce(
          (acc: any, cur: any) => !!acc || !!cur || isEqualZero(cur),
          false,
        )
          ? false
          : true,
      },
    });
    setIsEditing(false);
    setAddNewOptionScore(false);
    setScores(filteredOpts.map((sc: any) => sc.score));
    setOptions(newOptns);
  };
  const handleSaveLocalOptions = () => {
    const newOptns = [...options];
    const filteredOpts = question?.questionOptions?.map((opt: any, index: number) => ({
      ...opt,
      score: scores[index] ?? opt?.score,
    }));

    onSaveQuestion({
      optionalProps: {
        questionOptions: newOptns.map((opt1: string) => ({
          label: opt1,
          value: opt1,
          score: !isEqualZero(+filteredOpts.find((op: any) => op.value === opt1)?.score)
            ? +filteredOpts.find((op: any) => op.value === opt1)?.score
            : null,
        })),
        isScored: !filteredOpts.reduce(
          (acc: any, cur: any) => !!acc || !!cur || isEqualZero(cur),
          false,
        )
          ? false
          : true,
      },
    });
  };
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight + 50;
    }
  };
  const handleSetScores = (value2: any, index: number, passedScores: any) => {
    const newScores = [...passedScores];
    newScores[index] = value2 || value2 === 0 || value2 === '' ? value2 : null;
    setScores(newScores);
    tmpScores = [...newScores];
  };

  useEffect(() => {
    if (
      (scores?.[0] || isEqualZero(scores?.[0])) &&
      (scores?.[scores?.length - 1] || isEqualZero(scores?.[scores?.length - 1])) &&
      scores.length === options.length &&
      !scores
        ?.slice(1, scores.length - 1)
        .reduce((acc: any, cur: any) => !!acc && (!!cur || isEqualZero(cur)), true)
    ) {
      const arr = generateLinearNumbers(
        Number(scores?.[0]),
        scores?.[scores?.length - 1],
        scores?.length,
      );

      const updatedArray = [...scores.slice(0, 1), ...arr, ...scores.slice(arr?.length + 1)];
      setTimeout(() => {
        setScores([...updatedArray]);
      }, 200);
    }
  }, [scores?.[0], scores?.[scores?.length - 1]]);
  useEffect(() => {
    return () => {
      const isEqual = areArraysEqual(
        tmpScores,
        tmpQuestion.questionOptions.map((it: any) => it.score),
      );

      const oldOptions = tmpQuestion.questionOptions.map((opt: any) => opt.value);
      const hasOptionsChanged = !areArraysEqual(localOptionChanges, oldOptions);

      if (localValue) {
        handleAddToOptions(localValue, true, [...(oldOptions || [])]);
      } else if (
        hasOptionsChanged ||
        (!isEqual &&
          tmpScores?.length &&
          !tmpScores.reduce((acc: any, cur: any) => !!acc || !!cur || isEqualZero(cur), false)) ||
        (!isEqual &&
          tmpScores?.length &&
          tmpScores.reduce((acc: any, cur: any) => !!acc && (!!cur || isEqualZero(cur)), true))
      ) {
        onSaveQuestion({
          optionalProps: {
            questionOptions: tmpOptions.map((opt: string, idx: number) => ({
              label: hasOptionsChanged ? localOptionChanges[idx] : opt,
              value: hasOptionsChanged ? localOptionChanges[idx] : opt,
              score: !tmpScores.reduce(
                (acc: any, cur: any) => !!acc || !!cur || isEqualZero(cur),
                false,
              )
                ? null
                : !tmpScores[idx]
                  ? tmpQuestion?.questionOptions?.[idx]?.score ||
                    isEqualZero(tmpQuestion?.questionOptions?.[idx]?.score)
                    ? tmpQuestion?.questionOptions?.[idx]?.score
                    : null
                  : +tmpScores[idx] ?? null,
            })),
            isScored: !tmpScores.reduce(
              (acc: any, cur: any) => !!acc || !!cur || isEqualZero(cur),
              false,
            )
              ? false
              : true,
          },
        });
      }
    };
  }, []);
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(e.target.value as any);
    setValue(e.target.value as any);
    const textarea = inputRef.current;

    if (textarea) {
      textarea.style.width = 'auto';
      textarea.style.height = 'auto';

      const scrollWidth = textarea.scrollWidth;
      const maxWidth = 513;
      const minWidth = 120;

      const newWidth = Math.min(Math.max(scrollWidth, minWidth), maxWidth);
      textarea.style.width = `${newWidth}px`;

      const scrollHeight = textarea.scrollHeight;
      const maxHeight = 390;
      textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
      textarea.style.maxHeight = '272px';
    }
  };
  useEffect(() => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.width = '100%';
      textarea.style.height = 'auto';
    }
  }, [inputRef.current]);

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div
      className={clsx(
        'absolute pb-3 z-20 bg-white rounded-[12px] top-0 mt-6 mr-0 -right-[10px] border-2 ',
        addQuestionError
          ? '[&>*]:!text-error-feedback [&>button]:!border-error-feedback border-error-feedback'
          : 'border-purple',
        showScore ? 'min-w-[219px] ' : 'min-w-[175px] ',
        isEditingOption ? 'w-max' : '',
      )}
    >
      <div className='flex justify-start items-center flex-col pt-2'>
        {options.length ? (
          <div className='w-full flex justify-between items-center mb-1'>
            {selectedQuestionnairy?.questionnaire?.type !== 'E_CRF' ? (
              <>
                {!isStandardForm && !location.pathname?.includes('site-select') ? (
                  <p className='text-black text-xs font-normal w-2/12 pl-1'>Score</p>
                ) : null}
                <p className='text-black text-xs font-normal w-10/12 text-center pl-2'>Value</p>
              </>
            ) : (
              <p className='text-black text-xs font-normal w-full pl-4 text-center'>Value</p>
            )}
          </div>
        ) : null}
        <div
          className={clsx(
            'pt-1 w-full px-3 max-h-[197px] overflow-y-auto',
            options.length > 4 && 'pb-[25px]',
          )}
          ref={scrollRef}
        >
          {options.map((option: string, index: number) => (
            <div key={option} className='w-full flex justify-between items-start gap-x-2'>
              {showScore ? (
                <ScoreComponent
                  value={scores[index] ?? question?.questionOptions?.[index]?.score}
                  handleSetScores={(value1: number) => {
                    handleSetScores(value1, index, scores);
                  }}
                  scores={scores}
                />
              ) : null}
              <div
                className={clsx(
                  'flex justify-start  relative  w-full  rounded-[4px] mb-2 first-of-type:mt-0 px-1',
                  addQuestionError ? 'border-error-feedback' : '',
                  isEditingOption ? 'border-2 items-start' : 'items-start',
                )}
              >
                <EditOption
                  setIsEditingOption={setIsEditingOption}
                  handleCallBack={(newTitle: string) => {
                    if (newTitle) {
                      const newOptions = options.map((opt: any, idx: number) =>
                        idx === index ? newTitle : opt,
                      );
                      setOptions([...newOptions]);
                      onSaveQuestion({
                        optionalProps: {
                          questionOptions: newOptions.map((opt: string, idx: number) => ({
                            label: opt,
                            value: opt,
                            score: !tmpScores[idx]
                              ? question?.questionOptions?.[idx]?.score ||
                                isEqualZero(question?.questionOptions?.[idx]?.score)
                                ? question?.questionOptions?.[idx]?.score
                                : null
                              : +scores[idx] ?? null,
                          })),
                          isScored:
                            scores.length === newOptions.length &&
                            scores.reduce((acc, cur) => !!acc && (!!cur || isEqualZero(cur)), true),
                        },
                      });
                      const tmScores: any = newOptions.map((opt: string, idx: number) => ({
                        label: opt,
                        value: opt,
                        score: !tmpScores[idx]
                          ? question?.questionOptions?.[idx]?.score ||
                            isEqualZero(question?.questionOptions?.[idx]?.score)
                            ? question?.questionOptions?.[idx]?.score
                            : null
                          : +scores[idx] ?? null,
                      }));
                      tmpScores = tmScores?.map((sc: any) => sc?.score);
                      
                      setScores([...tmpScores]);
                    }
                  }}
                  setOptionChanges={setOptionChanges}
                  options={options}
                  defaultValue={option}
                  defaultIsOpen={false}
                  inputPlaceHolder='Type option'
                  inputContainerClassName='w-[80%]'
                  optionIndex={index}
                >
                  <span className='pl-1 break-words w-[220px] inline-block text-left !text-black pr-2 max-h-[270px] overflow-y-auto '>
                    {option}
                  </span>
                </EditOption>

                <img
                  onClick={(e) => handleDeleteOption(e, option)}
                  className='absolute right-2 top-[6px]'
                  width={16}
                  height={15}
                  src={process.env.PUBLIC_URL + '/assets/images/deleteIcon.svg'}
                />
              </div>
            </div>
          ))}
        </div>
        <div
          ref={containerRef}
          className={clsx(
            options?.length < 20 ? 'block' : '!hidden',
            'relative flex cursor-pointer  justify-center items-center w-full ',
            options?.length && 'mt-0',
          )}
        >
          {addNewOptionScore ? (
            <input
              id='new-option-score-input'
              ref={newOptionScoreInput}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  if (value) handleAddToOptions(value);
                }
              }}
              type='text'
              value={addNewOptionScoreValue}
              onChange={(e) => {
                setAddNewOptionScoreValue(e.target.value);
                setScores([...scores, e.target.value]);
                tmpScores = [...scores, e.target.value];
              }}
              onBlur={handleBlur}
              className='w-[33px] h-8 border-1 border-gray rounded-[4px] text-blue-ocean-deep focus:!border-1 ml-3 focus:!border-blue-normal focus:!rounded-[4px]'
            />
          ) : null}
          {isEditing ? (
            <>
              <textarea
                id='new-option-input'
                ref={inputRef}
                className={clsx(
                  'resize-none overflow-y-auto p-0 pl-1 pr-5 focus:outline-none outline-none border-2 border-blue-normal !rounded-[4px] min-h-[30px] w-full ml-3 mr-4',
                  !addNewOptionScore && '!w-full',
                )}
                style={{
                  minWidth: '120px',
                  maxWidth: '513px',
                  minHeight: '35px',
                  maxHeight: '272px',
                }}
                rows={1}
                onKeyDown={handleKeyDown}
                value={value}
                onChange={handleInput}
                onBlur={handleBlur}
              />
            </>
          ) : value ? (
            <div onClick={handleClick} className={clsx('pl-2 py-1 w-[152px] text-left')}>
              {value}
            </div>
          ) : (
            <div
              onClick={() => {
                handleSaveLocalOptions();
                handleClick();
              }}
              className={clsx(
                'box-content border-2 border-dashed w-full h-[27px] rounded-[6px] text-gray-medium text-sm flex justify-center items-center mx-3',
                addQuestionError ? 'border-error-feedback' : 'border-gray-light',
                showScore && !(isEditing && addNewOptionScoreValue) ? 'w-[195px] ' : 'w-[143px] ',
              )}
            >
              Add
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropDownProps;
