import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Calendar } from 'components/common';
import Select from 'components/common/Select';
import SelectToAdd from 'components/common/SelectToAdd';
import TextArea from 'components/common/TextArea';
import Input from 'components/common/TextInput';
import {
  areaOfMedicalOptions,
  interventionOptions,
  selectTrialPhaseOptions,
  studyTypeOptions,
} from 'constants/study';
import { addDays, differenceInDays, format } from 'date-fns';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiLoader, FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { createStudy, fetchDisease } from 'services/api/study';
import { DoctorSpecialty, IDisease, IStudy, Intervention, StudyType } from 'types/study';
import { useEffect, useState } from 'react';
import { Opt } from 'types/form';
import usePermissions from 'hooks/usePermissions';
import { FormattedMessage, useIntl } from 'react-intl';

export interface StudyForm {
  id: number;
  title: string;
  shortTitle: string;
  studyType: StudyType;
  medicalArea: DoctorSpecialty;
  interventionType: Intervention;
  numberOfPatient: number;
  conditions: number[];
  cipNumber: number;
  startDate: Date | string;
  enrollmentDate: Date | string;
  completionDate: Date | string;
  status?: string;
  sponsorName: string;
  sponsorEmail: string;
  trialPhase: string;
  logo: string;
  summaryStudy: string;
  primaryObjective: string;
  secondaryObjective: string;
  studyDesign: string;
  durationOfStudy?: string;
  exclusionCriteria: string;
  inclusionCriteria: string;
  financialIncentiveAmount: string;
}

const initialValues = {};

export default function CreateStudyForm() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { isGeneralAdmin } = usePermissions();
  const [conditionsOpt, setConditionOpt] = useState<Opt[]>([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [groups, setGroups] = useState<any>([]);

  const validationSchema = Yup.object({
    title: Yup.string().required(intl.formatMessage({ id: 'error.titleIsRequired' })),
    shortTitle: Yup.string().required(intl.formatMessage({ id: 'error.shortTitle' })),
    studyType: Yup.string().required(intl.formatMessage({ id: 'error.studyType' })),
    sponsorName: Yup.string().required(intl.formatMessage({ id: 'error.sponsorName' })),
    sponsorEmail: Yup.string().required(intl.formatMessage({ id: 'error.sponsorEmail' })),
    medicalArea: Yup.string().required(intl.formatMessage({ id: 'error.medicalArea' })),
    interventionType: Yup.string().required(intl.formatMessage({ id: 'error.interventionType' })),
    trialPhase: Yup.string().required(intl.formatMessage({ id: 'error.trialPhase' })),
    numberOfPatient: Yup.number()
      .typeError(intl.formatMessage({ id: 'error.numberOfPatient' }))
      .required(),
    cipNumber: Yup.string().required(intl.formatMessage({ id: 'error.cipNumber' })),
    conditions: Yup.array().required(intl.formatMessage({ id: 'error.conditions' })),
    startDate:
      Yup.date().required(intl.formatMessage({ id: 'error.startDate' })) ||
      Yup.string().required(intl.formatMessage({ id: 'error.startDate' })),
    enrollmentDate:
      Yup.date().required(intl.formatMessage({ id: 'error.enrollmentDate' })) ||
      Yup.string().required(intl.formatMessage({ id: 'error.enrollmentDate' })),
    completionDate:
      Yup.date().required(intl.formatMessage({ id: 'error.completionDate' })) ||
      Yup.string().required(intl.formatMessage({ id: 'error.completionDate' })),
    summaryStudy: Yup.string().required(intl.formatMessage({ id: 'error.summaryStudy' })),
    primaryObjective: Yup.string().required(intl.formatMessage({ id: 'error.primaryObjective' })),
    secondaryObjective: Yup.string().required(
      intl.formatMessage({ id: 'error.secondaryObjective' }),
    ),
    studyDesign: Yup.string().required(intl.formatMessage({ id: 'error.studyDesign' })),
    exclusionCriteria: Yup.string().required(intl.formatMessage({ id: 'error.exclusionCriteria' })),
    inclusionCriteria: Yup.string().required(intl.formatMessage({ id: 'error.inclusionCriteria' })),
    financialIncentiveAmount: Yup.string().required(
      intl.formatMessage({ id: 'error.financialIncentiveAmount' }),
    ),
  }).required();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<StudyForm>({
    resolver: yupResolver(validationSchema) as any,
    defaultValues: { ...(initialValues as StudyForm) },
  });

  const { data: diseaseList } = useQuery<IDisease[]>({
    queryKey: ['disease'],
    queryFn: () => fetchDisease(),
    refetchOnMount: 'always',
  });

  const { mutate: createStudyMutation, isLoading } = useMutation<IStudy, unknown, any, unknown>(
    (data: any) => createStudy(data),
    {
      onSuccess: (data) => {
        setIsSubmit(false);
        if (isGeneralAdmin()) navigate(`/study/${data.id}/users`);
        else navigate(`/study/${data.id}/study-builder`);
      },
    },
  );

  useEffect(() => {
    if (diseaseList && diseaseList?.length > 0) {
      setConditionOpt(diseaseList.map((ds) => ({ label: ds.name, value: ds.id })));
    }
  }, [diseaseList]);

  const values: any = watch();

  const checkFields = () => {
    const nameGroups = groups && groups?.length > 0 && groups?.filter((g: any) => g?.name !== '');

    return (
      values?.title &&
      values?.shortTitle &&
      values?.studyType &&
      ((values?.studyType === 'RANDOMIZED' && nameGroups && nameGroups?.length >= 2) || values?.studyType != 'RANDOMIZED') &&
      values?.sponsorName &&
      values?.sponsorEmail &&
      values?.medicalArea &&
      values?.interventionType &&
      values?.trialPhase &&
      values?.numberOfPatient &&
      values?.enrollmentDate &&
      values?.completionDate &&
      values?.startDate &&
      values?.conditions &&
      values?.cipNumber &&
      values?.summaryStudy &&
      values?.primaryObjective &&
      values?.secondaryObjective &&
      values?.studyDesign &&
      values?.inclusionCriteria &&
      values?.exclusionCriteria &&
      values.financialIncentiveAmount
    );
  };

  const onSubmit: SubmitHandler<StudyForm> = (data) => {
    setIsSubmit(true);
    data?.durationOfStudy && delete data.durationOfStudy;
    const randomizationGroups = groups && groups?.length > 0 ? groups?.filter((g: any) => g?.name !== '')?.map((gp: any) => gp?.name) : [];
    if (data?.studyType === 'RANDOMIZED' && randomizationGroups?.length < 2) return;
    const newData = {
      ...data,
      status: 'DEPLOYMENT',
      randomizationGroups,
      conditions: data.conditions?.map((c) => parseInt(`${c}`, 10)),
      numberOfPatient: parseInt(`${data.numberOfPatient}`, 10),
      startDate: data?.startDate && format(values?.startDate, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''),
      enrollmentDate:
        data?.enrollmentDate && format(values?.enrollmentDate, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''),
      completionDate:
        data?.completionDate && format(values?.completionDate, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''),
    };

    const formData = new FormData();
    data?.logo && formData.append('logo', data.logo);
    formData.append('request', new Blob([JSON.stringify(newData)], { type: 'application/json' }));
    createStudyMutation(formData);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleNewGroup = () => {
    if (groups?.length >= 4) return;
    setGroups([ ...groups, {
      title: `Randomized Group ${groups?.length + 1}`,
      name: ''
    }]);
  }

  const financeErrorMsg = errors?.financialIncentiveAmount?.message as string | undefined;
  return (
    <div className='bg-white h-fit flex  rounded-lg flex-col w-full'>
      {isLoading ? (
        <FiLoader className='w-20 h-20 animate-spin  self-center text-blue-ocean-deep' />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className='py-10 px-6 container mx-auto'>
          <h1 className='pl-6 text-[40px] font-sans leading-[2.625rem] text-blue-ocean-deep text-left'>
            {intl.formatMessage({ id: 'create-study.studyDetails' })}
          </h1>
          <div className='border-[1px] border-purple px-11 mt-4 rounded-lg'>
            <div className='w-full'>
              <Input
                register={register}
                onKeyDown={handleKeyDown}
                className='flex-1 mt-6'
                type='text'
                label='site.cipNumber'
                errors={errors}
                name='cipNumber'
              />
              <Input
                className='mt-8'
                onKeyDown={handleKeyDown}
                errors={errors}
                inputClassName='h-[4.875rem]'
                register={register}
                label='create-study.studyTitle'
                name='title'
              />
              <Input
                className='mt-8'
                errors={errors}
                onKeyDown={handleKeyDown}
                register={register}
                label='allStudies.shortTitle'
                name='shortTitle'
              />
            </div>
            <div className='w-full flex gap-8 mt-6'>
              <Select
                control={control}
                label={intl.formatMessage({ id: 'create-study.studyType' })}
                errors={errors}
                isSearchable={true}
                onBlur={() => {
                  setGroups([{
                    title: 'Randomized Group 1',
                    name: ''
                  }, {
                    title: 'Randomized Group 2',
                    name: ''
                  }])
                }}
                className='flex-1 [&>.open]:border-purple'
                placeholder={intl.formatMessage({ id: 'create-study.selectedStudyType' })}
                options={studyTypeOptions}
                name='studyType'
                optWrapperClassName='!border-purple border-1'
              />
              <Select
                control={control}
                label={intl.formatMessage({ id: 'create-study.interventionType' })}
                className='flex-1 [&>.open]:border-purple'
                errors={errors}
                isSearchable={true}
                placeholder={intl.formatMessage({ id: 'create-study.selectInterventionType' })}
                name='interventionType'
                options={interventionOptions}
                optWrapperClassName='!border-purple border-1'
              />
            </div>
            {values?.studyType === 'RANDOMIZED' &&
              <div className="w-full grid grid-cols-[48.8%_48.8%] gap-x-8 gap-y-4 mt-6">
                {groups && groups?.length > 0 && groups?.map((group: any, i: any) => (
                  <div className='' key={i}>
                    <Input
                      register={register}
                      onKeyDown={handleKeyDown}
                      className="flex-1 !mt-0 "
                      label={group?.title}
                      placeholder={intl.formatMessage({ id: 'create-study.groupName' })}
                      noTranslation={true}
                      errors={{
                        [`group-${i + 1}`]: {
                          message: (Object.keys(errors)?.length > 0 || isSubmit) && group?.name === '' ? intl.formatMessage({ id: 'error.randomizedNameIsRequired' }) : ''
                        }
                      }}
                      name={`group-${i + 1}`}
                      control={{
                        onChange: (e: any) => {
                          const newGroup = groups;
                          newGroup[i].name = e.target.value;
                          setGroups([ ...newGroup ])
                        }
                      }}
                    />
                  </div>
                ))}
                {groups?.length < 4 && <div onClick={handleNewGroup}
                                            className="w-[75px] cursor-pointer flex flex-col justify-center items-center">
                  <FiPlusCircle className="w-6 h-6 stroke-2 text-blue-ocean-deep" />
                  <p className="text-blue-ocean-deep pt-3 text-sm font-semibold ">
                    <FormattedMessage id="study.addGroup" />
                  </p>
                </div>}
              </div>}
            <div className='w-full flex gap-8 mt-8'>
              <Select
                control={control}
                isSearchable={true}
                label={intl.formatMessage({ id: 'create-study.medicalSpecialty' })}
                className='flex-1 [&>.open]:border-purple'
                errors={errors}
                placeholder={intl.formatMessage({ id: 'create-study.selectedArea' })}
                options={areaOfMedicalOptions}
                name='medicalArea'
                optWrapperClassName="!border-purple border-1 !h-[15rem] !max-h-[15rem] !min-h-[10rem]"
              />
              <Input
                register={register}
                onKeyDown={handleKeyDown}
                className='flex-1 !mt-0'
                type='number'
                label='site.noOfPatients'
                errors={errors}
                name='numberOfPatient'
              />
            </div>
            <SelectToAdd
              control={control}
              options={conditionsOpt}
              errors={errors}
              label={intl.formatMessage({ id: 'create-study.conditions' })}
              className='mt-8 w-full [&>.open]:border-purple'
              placeholder={intl.formatMessage({ id: 'create-study.selectCondition' })}
              name='conditions'
              optWrapperClassName="!border-purple border-1"
            />

            <div className='w-full flex gap-8 mt-[3.75rem]'>
              <Calendar
                control={control}
                errors={errors}
                className='flex-1 !my-0'
                label={intl.formatMessage({ id: 'create-study.studyStartDate' })}
                name='startDate'
                type='date'
                inputDisable={true}
              />
              <Calendar
                control={control}
                errors={errors}
                className='flex-1 !my-0'
                label={intl.formatMessage({ id: 'create-study.enrollmentDate' })}
                minDate={values.startDate}
                disabled={!values.startDate}
                name='enrollmentDate'
                type='date'
                inputDisable={true}
              />
              <Calendar
                control={control}
                errors={errors}
                className='flex-1 !my-0'
                label={intl.formatMessage({ id: 'create-study.studyEndDate' })}
                disabled={!values.startDate || !values.enrollmentDate}
                minDate={new Date(addDays(values.enrollmentDate, 1))}
                name='completionDate'
                type='date'
                inputDisable={true}
              />
              <Input
                register={register}
                readOnly
                onKeyDown={handleKeyDown}
                errors={errors}
                defaultValue={
                  values?.completionDate &&
                  values?.startDate &&
                  differenceInDays(new Date(values?.completionDate), new Date(values?.startDate))
                }
                label='create-study.durationOfStudy'
                name='durationOfStudy'
                className='flex flex-1 !mt-0'
              />
            </div>
            <div className="flex gap-8 w-full mt-8">
              <Input
                register={register}
                errors={errors}
                onKeyDown={handleKeyDown}
                label="create-study.nameOfTheSponsor"
                name="sponsorName"
                className="flex w-3/5 !mt-0"
              />
              <Input
                register={register}
                errors={errors}
                onKeyDown={handleKeyDown}
                label="create-study.sponsorEmail"
                name="sponsorEmail"
                className="flex w-3/5 !mt-0"
              />
            </div>
            <div className="w-full flex gap-8 mt-8">
              <div className="flex gap-8 w-full">
                <Select
                  control={control}
                  isSearchable={true}
                  label={intl.formatMessage({ id: 'create-study.selectTrialPhase' })}
                  className="w-1/2 [&>.open]:border-purple"
                  errors={errors}
                  placeholder={intl.formatMessage({ id: 'create-study.selectTrialPhase' })}
                  name="trialPhase"
                  options={selectTrialPhaseOptions}
                  optWrapperClassName="!border-purple border-1"
                />
              </div>
              {/* <FileUpload
              label={intl.formatMessage({ id: 'create-study.uploadLogo' })}
              register={register}
              onFileUpload={handleUpload}
              accept='.png,.WebP,.jpeg'
              name='logo'
            /> */}
            </div>
            <div className="w-full flex gap-8">
              <TextArea
                name="summaryStudy"
                className="mt-12 flex-1"
                control={control}
                onKeyDown={handleKeyDown}
                errors={errors}
                label={intl.formatMessage({ id: 'create-study.summaryOfStudy' })}
              />
            </div>
            <div className="w-full flex gap-8">
              <TextArea
                name="primaryObjective"
                className="mt-8 flex-1"
                control={control}
                onKeyDown={handleKeyDown}
                errors={errors}
                label={intl.formatMessage({ id: 'create-study.primaryObjective' })}
                rows={4}
              />
            </div>
            <div className="w-full flex gap-8">
              <TextArea
                name='secondaryObjective'
                className='mt-16 flex-1'
                control={control}
                onKeyDown={handleKeyDown}
                label={intl.formatMessage({ id: 'create-study.secondaryObjective' })}
                rows={4}
                errors={errors}
              />
            </div>
            <div className='w-full flex gap-8'>
              <TextArea
                name='studyDesign'
                className='mt-16 flex-1'
                control={control}
                onKeyDown={handleKeyDown}
                label={intl.formatMessage({ id: 'create-study.studyDesign' })}
                rows={4}
                errors={errors}
              />
            </div>
            <div className='w-full flex gap-8'>
              <TextArea
                name='inclusionCriteria'
                className='mt-8 flex-1'
                control={control}
                onKeyDown={handleKeyDown}
                label={intl.formatMessage({ id: 'create-study.inclusionCriteria' })}
                rows={4}
                errors={errors}
              />
            </div>
            <div className='w-full flex gap-8'>
              <TextArea
                name='exclusionCriteria'
                className='mt-8 flex-1'
                control={control}
                onKeyDown={handleKeyDown}
                label={intl.formatMessage({ id: 'create-study.exclusionCriteria' })}
                rows={4}
                errors={errors}
              />
            </div>
            <div>
              <div className='w-full flex gap-2.5'>
                <Input
                  name='financialIncentiveAmount'
                  className='mt-9 w-32'
                  register={register}
                  onKeyDown={handleKeyDown}
                  label='create-study.amountOfFinancial'
                  placeholder={intl.formatMessage({ id: 'create-study.typeValue' })}
                />
                <p className='text-grey-dark text-base leading-6 font-inter self-end pb-3'>
                  {intl.formatMessage({ id: 'create-study.perParticipant' })}
                </p>
              </div>
              {financeErrorMsg ? <div className='text-error text-left'>{financeErrorMsg}</div> : ''}
            </div>
            <div className='flex gap-x-4 justify-end mt-7 mr-1 mb-6'>
              <button
                type='button'
                onClick={() => navigate('/')}
                className='w-[150px] h-12 p-2.5 rounded-[90px] border border-blue-ocean-deep text-blue-ocean-deep justify-center items-center gap-2.5 inline-flex'
              >
                {intl.formatMessage({ id: 'back' })}
              </button>
              <button
                type='submit'
                disabled={isLoading}
                className={`w-[150px] h-12 p-2.5 ${!checkFields() || isLoading ? 'bg-gray-light text-base-black cursor-not-allowed' : 'bg-blue-ocean-deep text-white'} rounded-[90px] justify-center items-center gap-2.5 inline-flex`}
              >
                {isLoading ? (
                  <FiLoader className='w-6 h-6 animate-spin self-center' />
                ) : (
                  intl.formatMessage({ id: 'study.createStudy' })
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
